import { render, staticRenderFns } from "./Storewide_discounts.vue?vue&type=template&id=29ebbe0c&scoped=true&"
import script from "./Storewide_discounts.vue?vue&type=script&lang=js&"
export * from "./Storewide_discounts.vue?vue&type=script&lang=js&"
import style0 from "./Storewide_discounts.vue?vue&type=style&index=0&id=29ebbe0c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29ebbe0c",
  null
  
)

export default component.exports